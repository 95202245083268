import OrganisationService from '../services/organisation.service';

const initialState = {
  organisations: [],
  organisation: null,
  registered: false,
  organisationUsers: []
}
export const organisations = {
  namespaced: true,
  state: initialState,
  actions: {
    async getOrganisations({ commit }) {
      let allOrganisations = await OrganisationService.getOrganisations();
      commit('populateOrganisations', allOrganisations.data.organisations);
    },
    async addNewOrganisation({ commit }, organisation) {
      await OrganisationService.addOrganisation(organisation)
      commit('registerOrganisation')
    },
    async addNewSubscriberOrganisation({ commit }, organisation) {
      await OrganisationService.addOrganisationFromSubscribedUser(organisation)
      commit('registerOrganisation')
    },
    async getOrganisation({ commit }, uri) {
      const response = await OrganisationService.getOrganisation(uri)
      commit('populateOrganisation', response.data)
    },
    async getUserSubscriptionOrganisations({ commit }, uri) {
      let allOrganisations = await OrganisationService.getUserSubscriptionOrganisations(uri);
      commit('populateOrganisations', allOrganisations.data.organisations);
    },
    async addOrganisationFromSubscribedUser({ commit }, organisation) {
      await OrganisationService.addOrganisationFromSubscribedUser(organisation)
      commit('registerOrganisation')
    },
    async getOrganisationUsers({ commit }, uri) {
      try {
        const response = await OrganisationService.getOrganisationUsers(uri)
        if (response.data) {
          commit('popuplateOrganisationUsers', response.data.users)
        }
        else {
          commit('popuplateOrganisationUsers', [])
        }
      }
      catch (error) {
        console.log('error', error)
      }
    },
    async addUser({ commit }, userDetails) {
      try {
        const response = await OrganisationService.addUserToOrganisation(userDetails)
        commit('registerSuccess');
        return response.data
      }
      catch (error) {
        commit('registerFailure');
        return error;
      }
    },
  },
  mutations: {
    registerOrganisation(state) {
      state.registered = true;
    },
    populateOrganisations(state, organisations) {
      state.organisations = organisations
    },
    populateOrganisation(state, organisation) {
      state.organisation = organisation
    },
    popuplateOrganisationUsers(state, organisationUsers) {
      state.organisationUsers = organisationUsers
    },
    registerSuccess(state) {
      state.registered = true
    },
    registerFailure(state) {
      state.registered = false
    }
  },
}