import SubscriptionService from '@/services/subscription.service';

const initialState = {
  subscriptions: [],
  subscription: null,
  subsriber: false,
  subscriberMessage: null
};

export const subscriptions = {
  namespaced: true,
  state: initialState,
  actions: {
    async getSubscriptions({ commit }) {
      let allSubscriptions = await SubscriptionService.getSubscriptions();
      commit('populateSubscriptions', allSubscriptions.data.subscriptions);
    },
    async signUpUserForSubscription({ commit }, subscription) {
      await SubscriptionService.signUpForSubscription(subscription);
      commit('registerSubscription');
    },
    async getUserSubscriptions({ commit }, userId) {
      try {
        let userSubscriptions = await SubscriptionService.getUserSubscriptions(userId);
        commit('populateSubscriptions', userSubscriptions.data.subscriptions);
      } catch (e) {
        console.log('an error occurred');
      }
    },
    async deleteSubscription({ commit }, subscription) {
      try {
        console.log('subscription to delete', subscription);
        let response = await SubscriptionService.deleteSubscription(subscription);
        commit('subscriberMessage', response.data.message);
        // Refresh the subscriptions list
        await this.dispatch('subscriptions/getUserSubscriptions', subscription.userId);
      } catch (e) {
        console.log('an error occurred while deleting the subscription');
      }
    }
  },
  mutations: {
    registerSubscription(state) {
      state.subsriber = true;
    },
    populateSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    subscriberMessage(state, message) {
      state.subscriberMessage = message;
    }
  }
};