import DocumentService from '../services/document.service';

const initialState = {
    document: null,
    documents: [],
    downLoading: false,
    upLoading: false,
    document_name: null,
    documentText:null
}
export const documents = {
    namespaced: true,
    state: initialState,
    actions: {
        async getDocuments({ commit }) {
            let allDocuments = await DocumentService.getAllDocuments();
            allDocuments.data.documents.forEach(document => {
                if (document.filetype === "pdf"){
                    document.logo_source = "pdf-96.png"
                }
                else if(document.filetype ==="csv"){
                    document.logo_source = "csv-96.png"
                }
                else if(['jpg', 'jpeg'].includes(document.filetype)){
                    document.logo_source = "jpeg-64.png"
                }
                else if(document.filetype==="png"){
                    document.logo_source = "png-96.png"
                }
                else{
                    document.logo_source = "word-96.png"
                }
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        async getUserDocuments({ commit }) {
            let allDocuments = await DocumentService.getUserDocuments();
            allDocuments.data.documents.forEach(document => {
                if (document.filetype === "pdf"){
                    document.logo_source = "pdf-96.png"
                }
                else if(document.filetype ==="csv"){
                    document.logo_source = "csv-96.png"
                }
                else if(['jpg', 'jpeg'].includes(document.filetype)){
                    document.logo_source = "jpeg-64.png"
                }
                else if(document.filetype==="png"){
                    document.logo_source = "png-96.png"
                }
                else{
                    document.logo_source = "word-96.png"
                }
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        async getOrganisationDocuments({commit}, uri){
            let allDocuments = await DocumentService.getOrganisationDocuments(uri);
            allDocuments.data.documents.forEach(document => {
                if (document.filetype === "pdf"){
                    document.logo_source = "pdf-96.png"
                }
                else if(document.filetype ==="csv"){
                    document.logo_source = "csv-96.png"
                }
                else if(['jpg', 'jpeg'].includes(document.filetype)){
                    document.logo_source = "jpeg-64.png"
                }
                else if(document.filetype==="png"){
                    document.logo_source = "png-96.png"
                }
                else{
                    document.logo_source = "word-96.png"
                }
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        async download({commit}, file_id){
            await DocumentService.download(file_id);
            commit('populateDocumentDetails', 'download')
        },
        async readDocument({commit}, fileId){
            let documentText = await DocumentService.readDocument(fileId)
            commit('populateDocumentText', documentText.data)
        },
        clearDocumentText({commit}){
            commit('populateDocumentText', null)
        },
        async delete({commit}, file_id){
            await DocumentService.delete(file_id)
            commit('populateDocumentDetails', 'delete')
        },
        async deleteOrganisationDocument({commit}, file_id){
            await DocumentService.deleteOrganisationDocument(file_id)
            commit('populateDocumentDetails', 'delete')
        },
        async select({commit}, document_name){
            commit('populateDocumentName', document_name)
        },
        async setErrorContent({commit}, content){
            commit('populateDocumentDetails', content)
        },
        async deleteSubscriberDocument({ dispatch }, documentId) {
            try {
                await DocumentService.deleteSubscriberDocument(documentId);
                dispatch('getUserDocuments'); // Reload documents after deletion
            } catch (error) {
                console.error('Error deleting document:', error);
            }
        }
    },
    mutations: {
        populateDocuments(state, documents) {
            state.documents = documents;
        },
        populateDocumentText(state, documentText){
            state.documentText = documentText
        },
        populateDocumentDetails(state, document){
            state.document = document
        },
        populateDocumentName(state, document_name){
            state.document_name = document_name
        }
    },
}