import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/auth";

class AuthService {
  async login(user) {
    console.log(user)
    const response = await axios
      .put(API_URL, {
        email: user.email,
        password: user.password
      });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  }
  async refreshToken(){
    try{
      let user = await axios.get(API_URL, { headers: authHeader() })
      localStorage.setItem('user', JSON.stringify(user.data));
      return user.data
    }
    catch(error){
      return false
    }
  }
  
  logout() {
    localStorage.removeItem('user');
  }
  async register(user) {
    // registers plain user account - free account
    return await axios.post(API_URL, {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    });
  }
  async adminUpdateUser(user) {
    // updates plain user account - free account
    var formData = new FormData()
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('role', user.role)
    formData.append('email', user.email)
    formData.append('verified', user.verified)
    if(user.new_email){
      formData.append('new_email', user.new_email)
    }
    return await axios.put(API_URL + '/admin', formData, { headers: authHeader()})
  }
  async adminRegister(user){
    var formData = new FormData()
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('role', user.role)
    formData.append('email', user.email)
    formData.append('password', user.password)
    return await axios.post(API_URL + '/admin', formData, { headers: authHeader()})
  }
  async verify(user) {
    // registers plain user account - free account
    return await axios.post(API_URL + '/verify', {
      email: user.email,
      verification_code: user.verification_code,
      password: user.password,
    });
  }
  async delete(email){
    await axios.delete(API_URL + '/delete/' + email, { headers: authHeader() });
  }
}
export default new AuthService();
