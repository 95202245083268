import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + 'subscriptions';

class SubscriptionService {
  signUpForSubscription(subscription){
    return axios.post(API_URL, {
      user: subscription.user,
      product: subscription.product,
      subscription_length: subscription.subscription_length
    })
  }
  editSubscription(subscription){
    return axios.put(API_URL, {
      id: subscription.id,
      user: subscription.user,
      product: subscription.product,
      subscription_length: subscription.subscription_length
    })
  }
  getSubscriptions() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  getSubscription() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  async getUserSubscriptions(userId){
    console.log('Getting user subscriptions for user:', userId);
    return await axios.get(API_URL + '/users/' + userId, { headers: authHeader() });
  }
  async deleteSubscription(subscription) {
    const subscription_id = subscription.id;
    return await axios.delete(`${API_URL}/users/${subscription.user_id}`, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      },
      data: { subscription_id }
    });
  }
}
export default new SubscriptionService();
