import axios from 'axios';
import authHeader from './auth-header';

const ORGANISATIONS_API_URL = process.env.VUE_APP_API_URL + '/groups';

class GroupService {
  async delete(group){
    await axios.delete(ORGANISATIONS_API_URL + '/delete/' + group, { headers: authHeader() });
  }
  async removeUser(email){
    await axios.delete(ORGANISATIONS_API_URL + '/users/delete/' + email, { headers: authHeader() });
  }
  async getGroups() {
    return await axios.get(ORGANISATIONS_API_URL, { headers: authHeader() });
  }
  async getGroup(uri){
    return await axios.get(ORGANISATIONS_API_URL + '/' + uri, {headers: authHeader() });
  }
  async addGroup(group){
    var formData = new FormData()
    formData.append('name', group.name)
    formData.append('lead_user_email', group.lead_user_email)
    return await axios.post(ORGANISATIONS_API_URL, formData, { headers: authHeader() })
  }
  async addUserToGroup(user){
    var formData = new FormData()
    formData.append('email', user.email)
    formData.append('uri', user.uri)
    return await axios.post(ORGANISATIONS_API_URL + '/users', formData, { headers: authHeader() })
    // to do: define org to add user to 
  }
}
export default new GroupService();
