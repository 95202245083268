import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Public/HomeView.vue";
import Login from "../views/Public/LoginView.vue";
import About from "../views/Public/AboutView.vue";
import Register from "../views/Public/RegisterView.vue";
import axios from 'axios';
import authHeader from '../services/auth-header';
import store from '@/store/index.js';

const API_URL = process.env.VUE_APP_API_URL + "/auth";
// lazy-loaded
const Profile = () => import("../views/Auth/ProfileView.vue")
const AdminDashboard = () => import("../views/Admin/AdminDashboardView.vue")
const AdminRegisterUser = () => import("../views/Admin/AdminRegisterUserView.vue")
const DocumentUpload = () => import("../components/Documents/DocumentUploadView.vue")
const PersonalDocuments = () => import("../components/Documents/PersonalDocuments.vue")
const OrganisationDocuments = () => import("../components/Organisations/OrganisationDocumentsView.vue")
const AdminRegisterOrganisation = () => import("../views/Admin/AdminAddOrganisationView.vue")
const MobileMenu = () => import("../components/Navbar/MobileMenuView.vue")
const Organisations = () => import("../views/Admin/OrganisationsView.vue")
const Verify = () => import("../views/Auth/VerifyView.vue")
const ProductSelect = () => import("../components/Products/ProductSelectView.vue")
const Enquiry = () => import("../views/Public/EnquiryView.vue")
const ManageOrganisationView = () => import("../views/Admin/ManageOrganisationView.vue")
const OrganisationAddUser = () => import("../views/Admin/OrganisationAddUserView.vue")
const ManageSubscriptions = () => import("../views/SuperAdmin/ManageSubscriptionsView.vue")
const AddSubscription = () => import("../components/Subscriptions/SubscriptionAddForm.vue")
const Payment = () => import("../views/Public/PaymentView.vue")
const ThankYou = () => import("../views/Public/ThankYouView.vue")
const Sorry = () => import("../views/Public/SorryView.vue")
const UserOrganisationManagement = () => import("../views/Auth/UserOrganisationManagement.vue")
const DocumentAnalysis = () => import("../views/Auth/DocumentAnalysis.vue")


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/mobile-menu',
    component: MobileMenu
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    // lazy-loaded
    component: ManageSubscriptions,
  },
  {
    path: "/add-subscription",
    name: "add subscription",
    // lazy-loaded
    component: AddSubscription,
  },
  {
    path: "/organisations",
    name: "organisations",
    // lazy-loaded
    component: Organisations,
  },
  {
    path: "/admin-dashboard",
    name: "admin-dashboard",
    // lazy-loaded
    component: AdminDashboard,
  },
  {
    path: "/add-member",
    name: "add-member",
    // lazy-loaded
    component: AdminRegisterUser,
  },
  {
    path: "/add-organisation",
    name: "add-organisation",
    // lazy-loaded
    component: AdminRegisterOrganisation,
  },
  {
    path: "/Organisation Management",
    // lazy-loaded
    component: UserOrganisationManagement,
  },
  {
    path: "/Organisation%20Management",
    // lazy-loaded
    component: UserOrganisationManagement,
  },
  {
    path: "/documents",
    component: DocumentUpload,
  }, 
  {
    path: "/Document%20Analysis",
    component: DocumentAnalysis
  },
  {
    path: '/subscriber-add-organisation',
    name: 'SubscriberAddOrganisation',
    component: () => import('@/views/Subscriber/SubscriberAddOrganisationView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: "/Document Analysis",
    component: DocumentAnalysis
  },
  {
    path: "/personal-documents",
    component: PersonalDocuments,
  }, 
  {
    path: "/organisation-documents/:uri?",
    component: OrganisationDocuments,
    props:true
  },
  {
    path: "/verify/:verification_code?",
    component: Verify,
    props:true
  },
  {
    path: "/organisation/:uri?",
    name: 'organisation',
    component: ManageOrganisationView,
    props: true
  }, 
  {
    path: "/add-organisation-member/:uri?",
    name: 'organisation member',
    component: OrganisationAddUser,
    props: true
  },   
  {
    path: "/products",
    component: ProductSelect,
  },
  {
    path: "/enquiry",
    component: Enquiry,
  },
  {
    path: "/payment",
    component: Payment,
    props:true
  },
  {
    path: "/thank-you/:session_id?",
    component: ThankYou,
    props:true
  },
  {
    path: "/sorry",
    component: Sorry
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/','/home','/login', '/register', '/about', '/mobile-menu', '/enquiry', '/products'];
  const authRequired = !publicPages.includes(to.path) && !to.path.startsWith('/verify') && !to.path.startsWith('/thank-you');
  try{
      if (authRequired) {
        let resp = await axios.get(API_URL, { headers: authHeader() })
        if (resp.status==200){
          if(resp.data.loggedin==='false'){
            store.commit('auth/logout')
            next('/');
          }
        }
      }
      next();
  }
  catch(err){
    console.log('an error occurred')
    store.dispatch('auth/logout')
    next('/home');
  }
});

export default router;
