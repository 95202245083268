<template>
    <div v-if="isSuperAdmin" class="navbar-nav ml-auto">
        <NavbarSuperAdminView/>
    </div>
    <div class="navbar-nav mr-auto" v-for="subscription, index in this.subscriptions" :key="index">
        <li  class="nav-item">
            <router-link :to="subscription.product" class="nav-link">
                {{ subscription.product }}
                <!-- <font-awesome-icon :icon="subscription.product +'-icon'" :class="getIconClass(subscription.product)" />{{ subscription.product }} -->
            </router-link>            
        </li>
    </div>    
    <!-- <div class="navbar-nav mr-auto">
        <li class="nav-item">
            <router-link to="/personal-documents" class="nav-link">
                <font-awesome-icon icon="fa-file" class="doc-icon" />My Documents
            </router-link>
        </li>
    </div> -->
    <div class="navbar-nav ml-auto">
        <li class="nav-item">
            <router-link to="/profile" class="nav-link">
                <font-awesome-icon icon="user" />
                {{ currentUser.email }}
            </router-link>            
        </li>
    </div>
    <div class="navbar-nav mr-auto">
        <li class="nav-item">
            <a class="nav-link" @click.prevent="logout">
                <font-awesome-icon icon="sign-out-alt" /> Logout
            </a>
        </li>
    </div>
</template>
  
<script>
import NavbarSuperAdminView from './NavbarSuperAdminView'
export default {
    name: 'NavbarMainMenuView',
    components: {NavbarSuperAdminView},
    data(){
        return {
            
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.resizer.windowWidth;
        },
        basedOnWindowWidth() {
            if (this.windowWidth > 768) {
                return true;
            } return false;
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        baseUserAccess() {
            return this.currentUser && this.currentUser['role'] &&
                (this.currentUser['role'] === "Read Only" || this.currentUser['role'] === "General User")
        },
        isSuperAdmin() {
            if (this.currentUser && this.currentUser['role']) {
                return this.currentUser['role'] === "Super Administrator"
            }
            return false
        },
        isAdmin() {
            if (this.currentUser && this.currentUser['role']) {
                return this.currentUser['role'] === "Administrator"
            }
            return false
        },
        getIconClass(product){
            return this.products[product]
        },
        subscriptions(){
            return this.currentUser.subscriptions;
        }
    },
    async mounted() {
        window.addEventListener(
            'resize',
            this.resizeMethod)
    },
    methods: {
        logout() {
            this.$store.reset()
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
        resizeMethod() {
            this.$store.commit('resizer/setWindowWidth')
        }
    }
};
</script>
  
<style>
.products {
    list-style-type: none;
    /* Remove bullets */
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.products:hover {
    background-color: rgb(250, 250, 250, 0.95);
    box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}


</style>