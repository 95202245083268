import axios from 'axios';
import authHeader from './auth-header';

const USERS_API_URL = process.env.VUE_APP_API_URL + 'users';
const ACCOUNT_RECOVERY_API_URL = process.env.VUE_APP_API_URL + 'account-recovery';

class UserService {
  getUsers() {
    return axios.get(USERS_API_URL, { headers: authHeader() });
  }

  getUser(userEmail) {
    return axios.get(`${USERS_API_URL}/${userEmail}`, { headers: authHeader() });
  }

  sendEmail(email) {
    return axios.post(ACCOUNT_RECOVERY_API_URL, { email });
  }

  async updateProfile(user) {
    console.log(user);
    return axios.put(`${USERS_API_URL}/profile`, user, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
  }
}

export default new UserService();