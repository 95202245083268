import axios from 'axios';
import authHeader from './auth-header';

const PRODUCTS_API_URL = process.env.VUE_APP_API_URL + '/products';
const SUBSCRIPTIONS_API_URL = process.env.VUE_APP_API_URL + '/subscriptions';


class ProductService {
  async getProducts() {
    // only members can view organisation payments
    return await axios.get(PRODUCTS_API_URL);
  }
  async getUserProducts(userId) {
    return await axios.get(SUBSCRIPTIONS_API_URL + '/user_products/' + userId, { headers: authHeader() });
  }
}
export default new ProductService();
